import { Component, OnDestroy } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  CreateDailyReportComponent,
} from '../components/create-daily-report/create-daily-report.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from '../core/projects.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-projects-wrapper',
  templateUrl: './projects-wrapper.component.html',
  styleUrls: ['./projects-wrapper.component.scss'],
  animations: [
    trigger('leftAnimation', [
      state(
        '*',
        style({
          width: '100%',
        })
      ),
      state(
        'sidenavOpen',
        style({
          width: 'calc(100% - 535px)',
        })
      ),
      state(
        'sidenavClosed',
        style({
          width: '100%',
        })
      ),
      transition('*=>sidenavOpen', animate('500ms')),
      transition('*=>sidenavClosed', animate('500ms')),
    ]),
    trigger('rightAnimation', [
      state(
        '*',
        style({
          width: '0px',
        })
      ),
      state(
        'sidenavOpen',
        style({
          width: '535px',
        })
      ),
      state(
        'sidenavClosed',
        style({
          width: '0px',
        })
      ),
      transition('*=>sidenavOpen', animate('500ms')),
      transition('*=>sidenavClosed', animate('500ms')),
    ]),
    trigger('legendAnimation', [
      state(
        'visible',
        style({
          transform: 'translate(0%, 0%) scale(1)',
          opacity: 1,
        })
      ),
      state(
        'void, hidden',
        style({
          transform: 'translate(-30%, 25%) scale(0.4)',
          opacity: 0,
        })
      ),
      transition('* => visible', animate('250ms')),
      transition('* => void, * => hidden', animate('250ms')),
    ]),
  ],
})
export class ProjectsWrapperComponent implements OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  isFullScreen = false;
  showSidenav = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
  ) {
    this.projectsService.closeCreateDailyReportDialog
      .pipe()
      .subscribe(() => {
        this.dialog.closeAll();
      });

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;
      });
  }

  handleAdd() {
    const currentRoute = this.router.url.split('?')[0];

    if (currentRoute === '/projects') {
      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        data: {x: 3},
        panelClass: 'floating-dialog-box',
      });
    }

    const projectDetailsPageUrlRegex = /^\/projects\/[a-zA-Z0-9]{20,25}$/;
    if (projectDetailsPageUrlRegex.test(currentRoute)) {
      this.dialog.open(CreateDailyReportComponent, {
        disableClose: false,
        panelClass: 'floating-dialog-box',
        data: {
          projectId: currentRoute.split('/').pop(),
        }
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
