import { BaseModel } from './base-model';

export class PayappItem extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'PayappItem'; }

  /* tslint:disable */
  site_name: string;
  report_date: Date|any;
  daily_report_id: string;
  item: string;
  description: string;
  bid_item_quantity: number;
  unit_price: number;
  quantity: number;
  uom: string;
  pay_app_status: string;
  pay_app_comments: string;
  report_bid_item_id: string;
  pay_app_id: number;
  site_id: string;
  created_by_id: string;
  created_by: string;
  /* tslint:enable */

  static toPayload(
    payappItems: PayappItem[], 
    itemMaps: { [key: string]: any }, 
    payappName: string
  ): { name: string, paid: any[], deferred: any[], rejected: any[] } {
      return payappItems.reduce((acc, item) => {
      const status = itemMaps?.status[item.report_bid_item_id] || 'ready';
      const comments = itemMaps?.comments[item.report_bid_item_id] || '';
      const key = status === 'deferred' ? 'deferred' : status === 'rejected' ? 'rejected' : 'paid';
      acc[key].push({
        id: item.report_bid_item_id,
        comments: comments
      });
      return acc;
    }, { name: payappName, paid: [], deferred: [], rejected: [] });
  }
}
