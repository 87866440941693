import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/guards';
import { DefaultComponent } from './layouts/default/default.component';
import { PortalComponent } from './layouts/portal/portal.component';
import { LoginComponent } from './modules/login/login.component';
import { Page404Component } from './modules/page404/page404.component';
import { Page403Component } from './modules/page403/page403.component';
import { HomeComponent } from './modules/home/home.component';
import { AnnotationComponent } from './modules/annotation/annotation.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import {
  ProjectsWrapperComponent
} from './modules/projects/wrapper/projects-wrapper.component';

const routes: Routes = [
  {
    path: '',
    component: PortalComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'annotate', component: AnnotationComponent, canActivate: [AuthGuard] },
      {
        path: 'project',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule),
      },
      {
        path: 'organization',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule),
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'issues',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/issue/issue.module').then(m => m.IssueModule),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
      },
      {
        path: 'submittals',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/submittals/submittals.module').then(m => m.SubmittalsModule),
      },
      {
        path: 'app-settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        component: ProjectsWrapperComponent,
        loadChildren: () =>
          import('./modules/projects/projects.module').then((m) => m.ProjectsModule),
      },
    ],
  },
  {
    path: '',
    component: DefaultComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'invite/accept/:code', component: SignUpComponent },
      {
        path: 'ga',
        loadChildren: () => import('./modules/guest-actions/guest-actions.module').then(m => m.GuestActionsModule),
      },
      {
        path: 'samples',
        loadChildren: () => import('./modules/samples/samples.module').then(m => m.SamplesModule),
      },
      { path: 'page-403', component: Page403Component },
      { path: '**', component: Page404Component },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
