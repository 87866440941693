import { Component, OnInit } from '@angular/core';
import { AppService, AuthService } from '../../services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Account } from '../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public activePath: string;
  public account: Account;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private appSrv: AppService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.activePath = this.router.url;
    this.account = this.authService.getCurrentUser();
  }

  logout() {
    this.authService.logout().subscribe({
      next: (resp: any) => {
        this.snackBar.open('Logged out successfully.', '', { duration: 3000 });
        this.router.navigate(['/login']);
      },
      error: (resp: any) => {
        if (resp.error.error) {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        }
      }
    });
  }

}
