import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {v1URL, v2URL} from 'src/app/shared/constants/urls.constants';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  closeCreateDailyReportDialog: Subject<any> = new Subject<any>();
  filteredProjectsForMap: Subject<any> = new Subject<any>();


  constructor(private commonServices: CommonService) {
  }

  fetchProjectsList(payload: any): Observable<any> {
    const urlConfig = v1URL.project;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  fetchDailyReports(payload: any): Observable<any> {
    const urlConfig = v1URL.dailyReports.fetchByProject;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  fetchProjectDetails(payload: any): Observable<any> {
    const urlConfig = v1URL.getProjectDetailsById;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  createDailyReport(payload: any): Observable<any> {
    const {siteId, ...params} = payload;
    const urlConfig = v1URL.dailyReport.create;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url({siteId}), params);
  }

  fetchProjectReports(payload: any): Observable<any> {
    const urlConfig = v1URL.projectReports.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  fetchPayappItems(payload: any): Observable<any> {
    const urlConfig = v1URL.payapp.fetchPayappItems;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  fetchPayapps(payload: any): Observable<any> {
    const {projectId, ...params} = payload;
    const urlConfig = v1URL.payapp.fetchPayapps;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url({projectId}), params.data);
  }

  fetchPayappDetails(payload: any): Observable<any> {
    const {payappId, projectId, qp} = payload;
    const urlConfig = v1URL.payapp.fetchPayappDetails;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url({projectId, payappId, qp}));
  }


  generatePayapp(payload: any): Observable<any> {
    const {projectId, ...params} = payload;
    const urlConfig = v1URL.payapp.create;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url({projectId}), params.data);
  }

  deletePayapp(projectId: string, payappId: string): Observable<any> {
    const urlConfig = v1URL.payapp.delete;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url({projectId, payappId}));
  }

  updatePayappStatus(payload: any): Observable<any> {
    const {dailyReportId, reportBidItemId, ...params} = payload;
    const urlConfig = v1URL.payapp.updatePayappStatus;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url({dailyReportId, reportBidItemId}), params.data);
  }

  fetchSiteWiseDailyReportSummary(payload: any): Observable<any> {
    const urlConfig = v2URL.siteWiseDailyReportSummary.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  fetchProjectMembers(payload: any): Observable<any> {
    const urlConfig = v1URL.projectMembers.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }
}
