<mat-form-field
  *ngIf="parentFormGroup.controls[controlName]"
  [formGroup]="parentFormGroup"
  [ngClass]="{
        'enabled-clear-selection': enableClearSelection &&
                                   parentFormGroup.controls[controlName].value &&
                                   parentFormGroup.controls[controlName].status !== 'DISABLED',
      }"
  appearance="legacy"
  class="form-field w-full"
  floatLabel="always"
  hideRequiredMarker
>
  <mat-label class="text-type-13">{{ label }}:</mat-label>
  <mat-select
    #select
    [formControlName]="controlName"
    [multiple]="enableMultiple"
    class="select"
    [ngClass]="{
            'enabled-clear-selections': (parentFormGroup.controls[controlName].status !== 'DISABLED') &&
            (enableClearSelection && parentFormGroup.getRawValue()[controlName])
          }"
    [panelClass]="optionsPanelClassName"
    [placeholder]="placeholder"
    (keydown)="$event.preventDefault() && $event.preventDefault()"
  >
    <mat-option
      *ngIf="loading; else loaded"
      class="dropdown-loading"
      disabled
    >
      <mat-spinner
        class="dropdown-loader"
        mode="indeterminate"
        [diameter]="20"
      >
      </mat-spinner>
    </mat-option>
    <ng-template #loaded>
      <form
        *ngIf="options.length && enableSearch"
        [formGroup]="searchForm"
      >
        <div class="filter-section search-section">
          <div class="search">
            <mat-icon svgIcon="search"></mat-icon>
            <input
              matInput
              formControlName="text"
              placeholder="Search"
              (keydown)="$event.stopPropagation()"
            >
          </div>
        </div>
      </form>
      <div *ngIf="filteredOptions?.length; else noData">
        <ng-container *ngFor="let option of filteredOptions; let i = index">
          <mat-option
            *ngIf="!option['children']"
            [value]="option.value"
            [disabled]="(option.value !== -1 && optionsDisabled) ||
              option.disabled"
            class="single-select-option"
          >
            {{ option.label }}
          </mat-option>
          <ng-container
            *ngIf="option['children']"
          >
            <mat-option
              [disabled]="true"
              [value]="option.value"
              class="single-select-option"
            >
              {{ option.label }}
            </mat-option>
            <mat-option
              *ngFor="let child of option['children']"
              [value]="child.value"
              [disabled]="child.disabled"
              class="single-select-option child-option"
            >
              {{ child.label }}
            </mat-option>
          </ng-container>
        </ng-container>

      </div>
      <ng-template #noData>
        <mat-option
          class="no-data"
          disabled>
          No options available
        </mat-option>
      </ng-template>
    </ng-template>
  </mat-select>
  <mat-icon
    *ngIf="parentFormGroup.controls[this.controlName].status !== 'DISABLED'"
    class="suffix-icon"
    svgIcon="chevron-down"
    matSuffix
  />
  <mat-icon
    (click)="clearSelection($event)"
    *ngIf="
          defaultValue && defaultValue.length ?
          (
             parentFormGroup.getRawValue()[controlName]?.join(',') !==
             defaultValue.join(',')
          ) : (
            (parentFormGroup.controls[this.controlName].status !== 'DISABLED') &&
            (
              (!enableMultiple && enableClearSelection && parentFormGroup.getRawValue()[controlName]) ||
              (enableMultiple && enableClearSelection && parentFormGroup.getRawValue()[controlName]?.length)
            )
          )"
    class="clear-selection"
    matSuffix
    matTooltip="Clear"
  >close
  </mat-icon>
  <mat-error *ngIf="error">{{ error }}</mat-error>
</mat-form-field>

