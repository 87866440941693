<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && !isLoading && handleClick.emit($event)"
  [ngClass]="{disabled: isDisabled, loading: isLoading}"
  [throttleTime]="1000"
  class="button primary-button w-full whitespace-nowrap flex flex-row items-center justify-center"
  [class.disabled]="isDisabled"
  mat-flat-button
  color="primary"
  preventMultipleClick
>
  <div
    *ngIf="!isLoading; else loader"
    class="button-content w-full truncate"
  >
    <ng-content></ng-content>
  </div>
  <ng-template #loader>
    <mat-spinner
      class="button-loader"
      mode="indeterminate"
      [diameter]="20"
    >
    </mat-spinner>
  </ng-template>
  <div [id]="buttonId" class="overlay"></div>
</button>
