const { version, name } = require('../../package.json');
export const environment = {
  production: true,
  API_ENDPOINT: 'https://api.sitenotesapp.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAh3sGhzoV8YDk4PnLHEiq5CKQfzhBaPMk',
  ESRI_MAPS_API_KEY: 'AAPK45b9c44352354d40b41b441845c22315vAgvGI4VSCMohRVMDU5jWidJLANn6MCIkc6lxsVYvDaMJ8iLlcRSRDdDipyGTj_1',
  PDFTRON_LICENSE_KEY: 'SITENOTES INC:OEM:SiteNotes::B+:AMS(20220228):D6576C021FD7F4D0A33352184F716F2F2292EC45067239098DD78375567931F5C7',
  PDFTRON_SERVER_URL: 'https://pdftron.sitenotesapp.com',
  SENTRY_DSN: 'https://7f47f6cc676c4d30bdda9798603d66d8@o456313.ingest.us.sentry.io/5449228',
  AUTOSAVE_DEBOUNCE: 1500,
  APP_VERSION: version,
  APP_NAME: name,
};
