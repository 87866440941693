<div
  class="wrapper"
  [class.map-full-screen]="isFullScreen"
>
  <div
    class="left"
    [@leftAnimation]="showSidenav ? 'sidenavOpen' : 'sidenavClosed'"
  >
    <app-map-wrapper class="map">
      <router-outlet name="map"></router-outlet>
    </app-map-wrapper>
    <div
      *ngIf="!isFullScreen"
      class="add-button"
      (click)="handleAdd()"
    >
      <mat-icon svgIcon="add"/>
    </div>
    <div
      class="content"
      *ngIf="!isFullScreen"
    >
      <router-outlet name="content"/>
    </div>
  </div>
  <div
    *ngIf="showSidenav"
    class="right"
    [@rightAnimation]="showSidenav ? 'sidenavOpen' : 'sidenavClosed'"
  >

  </div>
</div>
