import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { CommonService } from '../../../../services/common.service';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['../../styles.scss', './dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('select') input: ElementRef | undefined;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() loading = false;
  @Input() label = '';
  @Input() options: { value: any, label: string, disabled?: boolean, children?: any[]}[] = [];
  @Input() enableClearSelection = false;
  @Input() parentFormGroup: FormGroup = new FormGroup({});
  @Input() controlName = '';
  @Input() error = '';
  @Input() placeholder = '';
  @Input() focus = false;
  @Input() enableMultiple = false;
  @Input() optionsPanelClassName = '';
  @Input() disableAllOptions = false;
  @Input() defaultValue: any;
  @Input() enableSearch = false;
  @Output() checkForErrors: EventEmitter<any> = new EventEmitter();

  fieldSubscription: Subscription | undefined;

  isRequired = false;
  isDisabled = false;
  optionsDisabled = false;

  searchForm = new FormGroup({
    text: new FormControl(''),
  });

  filteredOptions = [];

  constructor(
    private commonService: CommonService,
    private store: Store,
    private cdRef: ChangeDetectorRef
  ) {
  }

  // Do not show field as touched and having error when clicked outside the field
  @HostListener('focusout', ['$event'])
  onBlur(event: any) {
    if (!this.error) {
      setTimeout(() => {
        this.parentFormGroup?.controls[this.controlName].markAsUntouched();
      }, 150);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.filteredOptions = this.options;
      this.filterOptions();
    }
  }

  ngOnInit(): void {
    const control = this.parentFormGroup.controls[this.controlName];
    this.isDisabled = control?.status === 'DISABLED';
    this.fieldSubscription = control?.valueChanges.subscribe((value) => {
      if (this.enableMultiple) {
        if (value?.includes(-1)) {
          this.optionsDisabled = true;
          this.parentFormGroup.controls[this.controlName].patchValue([-1], {emitEvent: false});
        } else if (value?.includes(-2)) {
          const selectedValues = [];
          this.options?.forEach(option => {
            selectedValues.push(option.value);
          });
          if (selectedValues.includes(-1)) {
            const index = selectedValues.indexOf(-1);
            selectedValues.splice(index, 1);
          }
          this.parentFormGroup.controls[this.controlName].patchValue([...selectedValues], {emitEvent: false});
          this.optionsDisabled = false;
        } else {
          this.optionsDisabled = false;
        }
      }

      // If field is marked as touched, mark it as untouched
      if (this.parentFormGroup?.controls[this.controlName].touched) {
        this.parentFormGroup?.controls[this.controlName].markAsUntouched();
      }

      // Get errors on the form field
      const errors = this.parentFormGroup.controls[this.controlName].errors;

      // If errors exist, handle it
      if (errors) {
        // Clear errors on the form field
        this.parentFormGroup.controls[this.controlName].setErrors(null);

        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);

        // Add errors again to the form field for showing messages in the future
        this.parentFormGroup.controls[this.controlName].setErrors(errors);
      } else {
        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);
      }
    });

    // Check if field is required
    if (control?.validator) {
      const validator = control.validator({} as AbstractControl);
      this.isRequired = !!(validator && validator['required']);
    }

    this.searchForm.controls.text.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(text => {
        console.log(text);
        this.filterOptions();
      });
  }

  clearSelection($event: Event) {
    $event.stopPropagation();
    this.parentFormGroup.controls[this.controlName].setValue(this.defaultValue || '');
    this.parentFormGroup.markAsDirty();
  }

  filterOptions() {
    console.log(this.searchForm.controls.text.value);
    if (this.searchForm.controls.text.value) {
      this.filteredOptions = this.options.filter(o => o.label.toLowerCase().includes(this.searchForm.controls.text.value?.toLowerCase()));
      this.cdRef.detectChanges();
    } else {
      this.filteredOptions = this.options;
    }
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
