/* NgRx */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectsState } from './projects.reducer';

// Selector functions
const projectsData = createFeatureSelector<ProjectsState>('sitenotes.projects');

export const projectsList = createSelector(projectsData, state => state.projectsList);

export const projectsFilterList = createSelector(projectsData, (state) => state.projectsFilterList);

export const sitesFilterList = createSelector(projectsData, (state, props) => state.sitesFilterData[props.projectId]);


export const projectMembersFilterList = createSelector(projectsData, (state) => state.projectMembersFilterList);



export const projectDetails = createSelector(projectsData, (state) => state.projectDetails);

export const siteWiseDailyReportsSummary = createSelector(projectsData, (state) => state.siteWiseDailyReportsSummary);


export const existingReportId = createSelector(projectsData, (state) => state.existingReportId);


export const projectReportsList = createSelector(projectsData, (state) => state.projectReportsList);

export const projectReport = createSelector(projectsData, (state) => state.projectReport);

export const selectPayappItems = createSelector(projectsData, state => state.payappItems);

export const selectPayapps = createSelector(projectsData, state => state.payapps);
