/* NgRx */
import { createAction, props } from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[projects] clear state';
export const ClearState = createAction(CLEAR_STATE);


/********** [/projects]  ************/

/******** FETCH PROJECTS LIST ***********/
const FETCH_PROJECTS_LIST_REQUEST = '[projects] fetch projects list request';
export const FetchProjectsListRequest = createAction(FETCH_PROJECTS_LIST_REQUEST, props<any>());

const FETCH_PROJECTS_LIST_SUCCESS = '[projects] fetch projects list success';
export const FetchProjectsListSuccess = createAction(FETCH_PROJECTS_LIST_SUCCESS, props<any>());

const FETCH_PROJECTS_LIST_FAILURE = '[projects] fetch projects list failure';
export const FetchProjectsListFailure = createAction(FETCH_PROJECTS_LIST_FAILURE, props<any>());

/********** [/projects/:projectId?tab=sites]  ************/

/******** FETCH PROJECT DETAILS ***********/
const FETCH_PROJECT_DETAILS_REQUEST = '[projects] fetch project details request';
export const FetchProjectDetailsRequest = createAction(FETCH_PROJECT_DETAILS_REQUEST, props<any>());

const FETCH_PROJECT_DETAILS_SUCCESS = '[projects] fetch project details success';
export const FetchProjectDetailsSuccess = createAction(FETCH_PROJECT_DETAILS_SUCCESS, props<any>());

const FETCH_PROJECT_DETAILS_FAILURE = '[projects] fetch project details failure';
export const FetchProjectDetailsFailure = createAction(FETCH_PROJECT_DETAILS_FAILURE, props<any>());

/******** FETCH SITE AND DAILY REPORTS SUMMARY ***********/
const FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_REQUEST = '[projects] fetch site wise daily report summary request';
export const FetchSiteWiseDailyReportSummaryRequest = createAction(FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_REQUEST, props<any>());

const FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_SUCCESS = '[projects] fetch site wise daily report summary success';
export const FetchSiteWiseDailyReportSummarySuccess = createAction(FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_SUCCESS, props<any>());

const FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_FAILURE = '[projects] fetch site wise daily report summary failure';
export const FetchSiteWiseDailyReportSummaryFailure = createAction(FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_FAILURE);

/******** FETCH PROJECT MEMBERS ***********/
const FETCH_PROJECT_MEMBERS_REQUEST = '[projects] fetch project members request';
export const FetchProjectMembersRequest = createAction(FETCH_PROJECT_MEMBERS_REQUEST, props<any>());

const FETCH_PROJECT_MEMBERS_SUCCESS = '[projects] fetch project members success';
export const FetchProjectMembersSuccess = createAction(FETCH_PROJECT_MEMBERS_SUCCESS, props<any>());

const FETCH_PROJECT_MEMBERS_FAILURE = '[projects] fetch project members failure';
export const FetchProjectMembersFailure = createAction(FETCH_PROJECT_MEMBERS_FAILURE);

/********** [/projects] [WRAPPER]  ************/

/******** CREATE DAILY REPORT ***********/
const CREATE_DAILY_REPORT_REQUEST = '[projects] create daily report request';
export const CreateDailyReportRequest = createAction(CREATE_DAILY_REPORT_REQUEST, props<any>());

const CREATE_DAILY_REPORT_SUCCESS = '[projects] create daily report success';
export const CreateDailyReportSuccess = createAction(CREATE_DAILY_REPORT_SUCCESS, props<any>());

const CREATE_DAILY_REPORT_FAILURE = '[projects] create daily report failure';
export const CreateDailyReportFailure = createAction(CREATE_DAILY_REPORT_FAILURE);

/******** CHECK DAILY REPORT STATUS ***********/
const CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_REQUEST = '[projects] check if daily report can be created request';
export const CheckIfDailyReportCanBeCreatedRequest = createAction(CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_REQUEST, props<any>());

const CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_SUCCESS = '[projects] check if daily report can be created success';
export const CheckIfDailyReportCanBeCreatedSuccess = createAction(CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_SUCCESS, props<any>());

const CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_FAILURE = '[projects] check if daily report can be created failure';
export const CheckIfDailyReportCanBeCreatedFailure = createAction(CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_FAILURE);

/********** [/projects/:projectId?tab=consolidated-reports]  ************/

/******** FETCH PROJECTS REPORTS LIST ***********/
const FETCH_PROJECT_REPORTS_LIST_REQUEST = '[projects] fetch project reports list request';
export const FetchProjectReportsListRequest = createAction(FETCH_PROJECT_REPORTS_LIST_REQUEST, props<any>());

const FETCH_PROJECT_REPORTS_LIST_SUCCESS = '[projects] fetch project reports list success';
export const FetchProjectReportsListSuccess = createAction(FETCH_PROJECT_REPORTS_LIST_SUCCESS, props<any>());

const FETCH_PROJECT_REPORTS_LIST_FAILURE = '[projects] fetch project reports list failure';
export const FetchProjectReportsListFailure = createAction(FETCH_PROJECT_REPORTS_LIST_FAILURE);

/******** FETCH PROJECTS REPORT ***********/
const FETCH_PROJECT_REPORT_REQUEST = '[projects] fetch project report request';
export const FetchProjectReportRequest = createAction(FETCH_PROJECT_REPORT_REQUEST, props<any>());

const FETCH_PROJECT_REPORT_SUCCESS = '[projects] fetch project report success';
export const FetchProjectReportSuccess = createAction(FETCH_PROJECT_REPORT_SUCCESS, props<any>());

const FETCH_PROJECT_REPORT_FAILURE = '[projects] fetch project report failure';
export const FetchProjectReportFailure = createAction(FETCH_PROJECT_REPORT_FAILURE);

/******** FETCH PAYAPP ITEMS ***********/
const FETCH_PAYAPP_ITEMS_REQUEST = '[projects] fetch payapp items request';
export const FetchPayappItemsRequest = createAction(FETCH_PAYAPP_ITEMS_REQUEST, props<any>());

const FETCH_PAYAPP_ITEMS_SUCCESS = '[projects] fetch payapp items success';
export const FetchPayappItemsSuccess = createAction(FETCH_PAYAPP_ITEMS_SUCCESS, props<any>());

const FETCH_PAYAPP_ITEMS_FAILURE = '[projects] fetch payapp items failure';
export const FetchPayappItemsFailure = createAction(FETCH_PAYAPP_ITEMS_FAILURE);

/******** FETCH PAYAPPS ***********/
const FETCH_PAYAPPS_REQUEST = '[projects] fetch payapps request';
export const FetchPayappsRequest = createAction(FETCH_PAYAPPS_REQUEST, props<any>());

const FETCH_PAYAPPS_SUCCESS = '[projects] fetch payapps success';
export const FetchPayappsSuccess = createAction(FETCH_PAYAPPS_SUCCESS, props<any>());

const FETCH_PAYAPPS_FAILURE = '[projects] fetch payapps failure';
export const FetchPayappsFailure = createAction(FETCH_PAYAPPS_FAILURE);

/******** FETCH PAYAPPS ***********/
const FETCH_PAYAPPDETAILS_REQUEST = '[projects] fetch payapp details request';
export const FetchPayappDetailsRequest = createAction(FETCH_PAYAPPDETAILS_REQUEST, props<any>());

const FETCH_PAYAPPDETAILS_SUCCESS = '[projects] fetch payapp details success';
export const FetchPayappDetailsSuccess = createAction(FETCH_PAYAPPDETAILS_SUCCESS, props<any>());

const FETCH_PAYAPPDETAILS_FAILURE = '[projects] fetch payapp details failure';
export const FetchPayappDetailsFailure = createAction(FETCH_PAYAPPDETAILS_FAILURE);

/******** GENERATE PAYAPP  ***********/
const GENERATE_PAYAPP_REQUEST = '[projects] generate payapp request';
export const GeneratePayappRequest = createAction(GENERATE_PAYAPP_REQUEST, props<any>());

const GENERATE_PAYAPP_SUCCESS = '[projects] generate payapp success';
export const GeneratePayappSuccess = createAction(GENERATE_PAYAPP_SUCCESS, props<any>());

const GENERATE_PAYAPP_FAILURE = '[projects] generate payapp failure';
export const GeneratePayappFailure = createAction(GENERATE_PAYAPP_FAILURE);

/******** DELETE PAYAPP  ***********/
const DELETE_PAYAPP_REQUEST = '[projects] delete payapp request';
export const DeletePayappRequest = createAction(DELETE_PAYAPP_REQUEST, props<any>());

const DELETE_PAYAPP_SUCCESS = '[projects] delete payapp success';
export const DeletePayappSuccess = createAction(DELETE_PAYAPP_SUCCESS, props<any>());

const DELETE_PAYAPP_FAILURE = '[projects] delete payapp failure';
export const DeletePayappFailure = createAction(DELETE_PAYAPP_FAILURE);

/******** UPDATE PAYAPP STATUS  ***********/
const UPDATE_PAYAPP_STATUS_REQUEST = '[projects] update payapp status request';
export const UpdatePayappStatusRequest = createAction(UPDATE_PAYAPP_STATUS_REQUEST, props<any>());

const UPDATE_PAYAPP_STATUS_SUCCESS = '[projects] update payapp status success';
export const UpdatePayappStatusSuccess = createAction(UPDATE_PAYAPP_STATUS_SUCCESS, props<any>());

const UPDATE_PAYAPP_STATUS_FAILURE = '[projects] update payapp status failure';
export const UpdatePayappStatusFailure = createAction(UPDATE_PAYAPP_STATUS_FAILURE);
